import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"

const DemoStartPage = () => {
  const body = [
    {
      component: "hero_block",
      headline: "CAS - Der Akku für dein Handwerk",
      text: "Entdecke unser breites Sortiment an CAS-Akku-Werkzeugen und lerne die Vorteile der kabellosen Freiheit auf der Baustelle kennen!",
      buttons: [
        {
          link: "/de-de/service/cordless-alliance-system",
          target: "",
          link_text: "Erfahre mehr",
        },
      ],
      image: {
        filename:
          "https://rothenberger.com/media/wysiwyg/Kampagnen/CAS_Kampagne_10-2021/CAS_system_SHK_03.jpg",
      },
    },
    {
      component: "product_cards_block",
      headline: "Unsere beliebtesten Produkte",
      link_text: "Mehr Produkte",
      link: "/de-de/produkte",
      product_items: [
        {
          image: {
            filename:
              "https://a.storyblok.com/f/125728/3500x2256/c434e24862/4076_romax-compact-tt_1000002115.png",
          },
          headline: "ROMAX COMPACT TT",
          text: "Pressmaschine",
          variants: "8",
          rating: "4.2",
          link: "/de-de/pressmaschine-compact/romax-comtt-basic-set-1-1x2ah-eu-1000002115",
        },
        {
          image: {
            filename:
              "https://a.storyblok.com/f/125728/1280x704/b3625cc4d3/supertronic-2000-1250.png",
          },
          headline: "SUPERTRONIC 2000",
          text: "Gewindeschneidmaschine",
          variants: "13",
          rating: "4.7",
          link: "/de-de/hand-elektrisches-gewindeschneiden/hand-elektrische-gewindeschneidkluppe-supertronic-2000-bis-2-71258",
        },
        {
          image: {
            filename:
              "https://a.storyblok.com/f/125728/3500x2256/c434e24862/4076_romax-compact-tt_1000002115.png",
          },
          headline: "ROMAX COMPACT TT",
          text: "Pressmaschine",
          variants: "8",
          rating: "4.2",
          link: "/de-de/pressmaschine-compact/romax-comtt-basic-set-1-1x2ah-eu-1000002115",
        },
        {
          image: {
            filename:
              "https://a.storyblok.com/f/125728/1280x704/b3625cc4d3/supertronic-2000-1250.png",
          },
          headline: "SUPERTRONIC 2000",
          text: "Gewindeschneidmaschine",
          variants: "13",
          rating: "4.7",
          link: "/de-de/hand-elektrisches-gewindeschneiden/hand-elektrische-gewindeschneidkluppe-supertronic-2000-bis-2-71258",
        },
      ],
    },
    {
      component: "promo_block",
      headline: "Tipps für einen erfolgreichen Tag",
      text: "In unserer RO Academy finden Sie praxisnahe Online-Tutorials, die Ihnen schnell und einfach den Umgang mit unseren ROTHENBERGER Kälte-Klima-Geräten vermitteln.",
      buttons: [
        {
          link: "/de-de/service/ro-academy",
          target: "",
          link_text: "Zur RO Academy",
        },
      ],
      image: {
        filename:
          "https://a.storyblok.com/f/125728/4272x2670/5436d2e7c7/ro-academy.jpg",
      },
    },
    {
      component: "product_cards_block",
      headline: "Unsere CAS-Akku-Produkte",
      link_text: "Jetzt entdecken",
      link: "/de-de/produkte",
      view: "slider",
      product_items: [
        {
          image: {
            filename:
              "https://a.storyblok.com/f/125728/3500x2256/c434e24862/4076_romax-compact-tt_1000002115.png",
          },
          headline: "ROMAX COMPACT TT",
          text: "Pressmaschine",
          variants: "8",
          rating: "4.2",
          link: "/de-de/pressmaschine-compact/romax-comtt-basic-set-1-1x2ah-eu-1000002115",
        },
        {
          image: {
            filename:
              "https://a.storyblok.com/f/125728/1280x704/b3625cc4d3/supertronic-2000-1250.png",
          },
          headline: "SUPERTRONIC 2000",
          text: "Gewindeschneidmaschine",
          variants: "13",
          rating: "4.7",
          link: "/de-de/hand-elektrisches-gewindeschneiden/hand-elektrische-gewindeschneidkluppe-supertronic-2000-bis-2-71258",
        },
        {
          image: {
            filename:
              "https://a.storyblok.com/f/125728/3500x2256/c434e24862/4076_romax-compact-tt_1000002115.png",
          },
          headline: "ROMAX COMPACT TT",
          text: "Pressmaschine",
          variants: "8",
          rating: "4.2",
          link: "/de-de/pressmaschine-compact/romax-comtt-basic-set-1-1x2ah-eu-1000002115",
        },
        {
          image: {
            filename:
              "https://a.storyblok.com/f/125728/1280x704/b3625cc4d3/supertronic-2000-1250.png",
          },
          headline: "SUPERTRONIC 2000",
          text: "Gewindeschneidmaschine",
          variants: "13",
          rating: "4.7",
          link: "/de-de/hand-elektrisches-gewindeschneiden/hand-elektrische-gewindeschneidkluppe-supertronic-2000-bis-2-71258",
        },
        {
          image: {
            filename:
              "https://a.storyblok.com/f/125728/3500x2256/c434e24862/4076_romax-compact-tt_1000002115.png",
          },
          headline: "ROMAX COMPACT TT",
          text: "Pressmaschine",
          variants: "8",
          rating: "4.2",
          link: "/de-de/pressmaschine-compact/romax-comtt-basic-set-1-1x2ah-eu-1000002115",
        },
        {
          image: {
            filename:
              "https://a.storyblok.com/f/125728/1280x704/b3625cc4d3/supertronic-2000-1250.png",
          },
          headline: "SUPERTRONIC 2000",
          text: "Gewindeschneidmaschine",
          variants: "13",
          rating: "4.7",
          link: "/de-de/hand-elektrisches-gewindeschneiden/hand-elektrische-gewindeschneidkluppe-supertronic-2000-bis-2-71258",
        },
      ],
    },
    {
      component: "divider_block",
    },
    {
      component: "cards_block",
      headline: "Was gibt es Neues?",
      link_text: "Weitere Neuigkeiten",
      link: "/",
      card_items: [
        {
          image: {
            filename:
              "https://a.storyblok.com/f/125728/3000x908/a021a38267/rothenberger_influencerday_2021_1.jpeg",
          },
          headline: "Erster internationaler ROTHENBERGER CAS Influencer Day",
          text: "Thema des aktionsreichen Events: Akku-Werkzeuge mit den kombistarken Batterien der Akku-Allianz CAS spielerisch erleben",
          link: "/de-de/unternehmen/presse-und-aktuelles/aktuelles/erster-internationaler-rothenberger-cas-influencer-day",
        },
        {
          image: { filename: "https://a.storyblok.com/f/125728/709x472/b889dc1aab/zoomlock-max-pressbacke.jpg" },
          headline: "Pressbacken made by ROTHENBERGER",
          text: "Pressbacken für ZoomLock MAX und ›B‹ MaxiPro stammen aus Kelkheim",
          link: "/de-de/unternehmen/presse-und-aktuelles/aktuelles/pressbacken-made-by-rothenberger",
        },
        {
          image: {
            filename:
              "https://a.storyblok.com/f/125728/709x472/d67d30caeb/rothenberger-rospimatic-cl.jpg",
          },
          headline: " Rohrreinigung leicht gemacht",
          text: "",
          link: "/de-de/unternehmen/presse-und-aktuelles/aktuelles/rohrreinigung-leicht-gemacht",
        },
        {
          image: { filename: "https://a.storyblok.com/f/125728/557x789/e314695b60/one-magazin-2021.jpg" },
          headline: "Neue Wege gehen",
          text: "Neues ONE Magazin erschienen",
          link: "/de-de/unternehmen/presse-und-aktuelles/aktuelles/neue-wege-gehen",
        },
      ],
    },
  ]

  return (
    <Layout language="de">
      <BodyBlocks body={body} />
    </Layout>
  )
}

export default DemoStartPage
